<template>
    <aside class="aside aside-fixed">
        <div class="aside-header" >
            <a href="#" class="aside-logo" style="font-size: 18px;">
<!--                <img src="https://assets-base.s3.amazonaws.com/img/firstbank/logo.png" class="img-fluid"  height="30px" width="100px" alt="FBNBank Logo">-->
                <img src="https://qusquare.com/Images/logo.png" height="40px" width="50px"/> Qoosquare
            </a>
            <a href="#" class="aside-menu-link">
                <menu-icon @click="show(true)"/>
                <x-icon  @click="show(false)"/>
<!--                <i data-feather="x"></i>-->
            </a>
            <a href="" id="chatContentClose" class="burger-menu d-none"><arrow-left-icon/></a>
            <a href="" id="contactContentHide" class="burger-menu d-none"><arrow-left-icon/></a>
        </div>
        <div class="aside-body">
            <div class="aside-loggedin">
                <div class="d-flex align-items-center justify-content-start">
<!--                    <a href="#" class="avatar"><img src="https://via.placeholder.com/500" class="rounded-circle" alt=""></a>-->
<!--                    <div class="aside-alert-link">-->
<!--                        <router-link to="#" class="new" data-toggle="tooltip" title="You have 2 unread messages"><i data-feather="message-square"></i></router-link>-->
<!--                        <router-link to="#" class="new" data-toggle="tooltip" title="You have 4 new notifications"><i data-feather="bell"></i></router-link>-->
<!--                        <a href="/" data-toggle="tooltip" title="Sign out"><i data-feather="log-out"></i></a>-->
<!--                    </div>-->
                </div>
                <div class="aside-loggedin-user">
                    <a href="#loggedinMenu" class="d-flex align-items-center justify-content-between mg-b-2" data-toggle="collapse">
                        <bar-loader v-if="companies[0] == null" class="custom-class" color="#6d0606" loading="loading" :size="150" sizeUnit="px"></bar-loader>
                        <h6 class="tx-semibold mg-b-0">{{companies[0] == null? '':companies[0].orgName.substring(0, 15)}}</h6>
                        <chevron-down-icon/>
                    </a>
<!--                    <p class="tx-color-03 tx-12 mg-b-0">{{$t('sidebar.administrator')}}</p>-->
                </div>
                <div class="collapse" id="loggedinMenu">
                    <p v-if="filteredCompanies.length > 0" class="mt-1 mb-0">{{$t('sidebar.switchCompany')}}</p>
                    <multiselect
                            v-if="filteredCompanies.length > 0"
                            label="orgName"
                            track-by="orgName"
                            style="font-size: 12px;"
                            class="mt-0" v-model="activeCompany"
                            :options="filteredCompanies"
                            :searchable="true"
                            :close-on-select="false"
                            :show-labels="false"
                            placeholder=""></multiselect>
                    <ul class="nav nav-aside mg-b-0">
                        <li class="nav-item"><router-link to="/personal-profile" class="nav-link"><edit-icon/> <span>{{$t('sidebar.personalProfile')}}</span></router-link></li>
                        <li class="nav-item"><router-link to="#" class="nav-link"><help-circle-icon/> <span>{{$t('sidebar.helpCenter')}}</span></router-link></li>
                        <li class="nav-item"><a href="#" @click="logout()" class="nav-link"><log-out-icon/> <span>{{$t('sidebar.signOut')}}</span></a></li>
                    </ul>
                </div>
            </div><!-- aside-loggedin -->
            <base-sidebar :menus="menu"></base-sidebar>
        </div>
    </aside>
</template>

<script>
    import BaseSidebar from "../../views/template/layouts/component/baseComponent/BaseSidebar";
    import {MenuIcon,XIcon, EditIcon, HelpCircleIcon, LogOutIcon, ArrowLeftIcon, ChevronDownIcon} from "vue-feather-icons"
    import {mapState} from "vuex";
    import $ from 'jquery'
    import PerfectScrollBar from 'perfect-scrollbar'
    import {stage} from "@/app.config.json";
    export default {
        props:['layout'],
        components:{
            BaseSidebar,
            MenuIcon,
            XIcon,
            EditIcon,
            HelpCircleIcon,
            LogOutIcon,
            ArrowLeftIcon,
            ChevronDownIcon
        },
        name: "SideBar",
        data(){
            return{
                model:{
                    activeCompany:{}
                },
                onBoardingMenu: [
                    // {
                    //     header: true,
                    //     classType: 'nav-label mt-3',
                    //     show:true,
                    //     title: 'sidebar.home'
                    // },
                    // {
                    //     icon: 'home',
                    //     classType: 'nav-item',
                    //     title: 'sidebar.moduleList',
                    //     href: '/dashboard',
                    //     show:true,
                    //     id:'v-step-0'
                    // },
                    {
                        header: true,
                        classType: 'nav-label mt-3',
                        show:true,
                        title: 'Organisation'
                    },
                    {
                        icon: 'users',
                        classType: 'nav-item',
                        title: 'Employees',
                        href: '/employees',
                        show:true,
                        id:'v-step-0'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Grades',
                        href: '/grades',
                        show:true,
                        id:'v-step-0'
                    },
                    {
                        icon: 'layers',
                        classType: 'nav-item',
                        title: 'Department',
                        href: '/departments',
                        show:true,
                        id:'v-step-1'
                    },
                    {
                        icon: 'shopping-bag',
                        classType: 'nav-item',
                        title: 'Conversations',
                        href: '/conversations',
                        show:true,
                        id:'users'
                    },
                    {
                        icon: 'file-text',
                        classType: 'nav-item',
                        title: 'Settings',
                        href: '/settings',
                        show:true,
                        id:'report'
                    },
                    {
                        header: true,
                        classType: 'nav-label mt-3',
                        title: 'Payroll',
                        show:true,
                    },
                    {
                        icon: 'file-text',
                        classType: 'nav-item',
                        title: 'Payroll Calendar',
                        href: '/calenders',
                        show:true,
                        id:'calender'
                    },
                    {
                        icon: 'file-text',
                        classType: 'nav-item',
                        title: 'Variable Payments',
                        href: '/variable-payments',
                        show:true,
                        id:'variable'
                    },
                    {
                        icon: 'file-text',
                        classType: 'nav-item',
                        title: 'Payslips',
                        href: '/payslips',
                        show:true,
                        id:'payslips'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      title: 'Project Management',
                      show:true,
                    },
                    {
                      icon: 'file-text',
                      classType: 'nav-item',
                      title: 'Projects',
                      href: '/projects',
                      show:true,
                      id:'Projects'
                    },
                    {
                      icon: 'layers',
                      classType: 'nav-item',
                      title: 'Project Tasks',
                      href: '/project-tasks',
                      show:true,
                      id:'ProjectTasks'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      title: 'Expense Management',
                      show:true,
                    },
                    {
                      icon: 'shopping-bag',
                      classType: 'nav-item',
                      title: 'Expenses',
                      href: '/expenses',
                      show:true,
                      id:'Expense'
                    },
                    {
                      header: true,
                      classType: 'nav-label mt-3',
                      title: 'Attendance Management',
                      show:true,
                    },
                    {
                      icon: 'users',
                      classType: 'nav-item',
                      title: 'Attendances',
                      href: '/attendances',
                      show:true,
                      id:'Attendances'
                    },
                ]

            }
        },
        methods:{
          logout(){
              localStorage.clear()
              let iframe = document.getElementById('f');
              let win;
              try {
                win = iframe.contentWindow;
              } catch(e) {
                win = iframe.contentWindow;
              }
              win.postMessage(JSON.stringify({key: 'token', method: "remove", data: {}}), "*");
              window.location = this.domain
            },

            officerFilteredByUserId(_item) {
                return this.officer.officers.filter(function (el) {
                    return el.officerUserID == _item;
                });
            },

            show: function(type){
                const app = $('body');
                type ===true ? app.addClass('show-aside') : app.removeClass('show-aside');
            }

        },
        computed:{
            ...mapState([
                'company',
                'officer',
                'auth'
            ]),
            domain(){
              console.log(stage)
              if (stage === 'dev')
                return 'https://dev-profile.qoosquare.com'
              else
                return 'https://profile.qoosquare.com'
            },
            companies(){
                return this.auth.userInfo.companies.data//.filter(it => it.orgId.toString() !== localStorage.orgId.toString())
            },
            filteredCompanies(){
                return this.auth.userInfo.companies.data.filter(it => it.orgId.toString() !== localStorage.orgId.toString())
            },
            activeCompany:{
                get(){
                    // return this.model.activeCompany;
                    return this.auth.userInfo.companies.data.filter(comp => comp.orgId === localStorage.orgId)[0]
                },
                set(val){
                    if (val != null) {
                        this.model.activeCompany = val
                        localStorage.orgId = this.model.activeCompany.orgId
                        this.$router.push('/preloader')
                        this.initDashboard()
                    }
                }
            },
            admin:{
                get(){
                    let access = false;
                    if (this.auth.userInfo.role.toUpperCase() === "USER" ||
                        this.auth.userInfo.role.toUpperCase() === "DIRECTOR" ||
                        this.auth.userInfo.role.toUpperCase() === "SIGNATORY" ||
                        this.auth.userInfo.role.toUpperCase() === "DIRECTOR&SIGNATORY" ||
                        this.auth.userInfo.role.toUpperCase() === "DIRECTORANDSIGNATORY")
                        access = true;
                    return access;
                }
            },
            customerService:{
                get(){
                    let access = false;
                    if (this.auth.userInfo.role.toUpperCase() === "CUSTOMERSERVICE")
                        access = true
                    return access;
                }
            },
            developer:{
                get(){
                    let access = false;
                    if (this.auth.userInfo.role.toUpperCase() === "DEVELOPER")
                        access = true
                    return access;
                }
            },
            menu:{
                get(){
                    if (this.layout === 'on-boarding-layout') {
                        return this.onBoardingMenu
                    } else if (this.layout === 'account-service-layout')  {
                        return this.accountServiceMenu
                    } else if (this.layout === 'payments-layout')  {
                        return this.paymentMenu
                    }else if (this.layout === 'collection-layout')  {
                        return this.collectionMenu
                    }else {
                        return this.onBoardingMenu
                    }
                }
            },
        },

        mounted() {
            const psSidebar = new PerfectScrollBar('.aside-body', {
                suppressScrollX: true
            });
            psSidebar.isAlive = true
            //this.$tours['myTour'].start()
        }
    }
</script>

<style scoped>

</style>