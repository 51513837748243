import axios from "axios";
import { baseUrl, qs2ProfileBaseUrl, rubiesBaseUrl, singleSignOnBaseUrl } from "../app.config";
import store from "../store/store";
import router from "../router";
import swal from "sweetalert";

const qooSquare = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});


qooSquare.interceptors.request.use(config => {
    const token = store.getters.getToken;
    const tok = store.getters["auth/getTok"];
    config.headers.Authorization = (tok == null)? token: tok;
    return config
});

qooSquare.interceptors.response.use(config => {
    if (config != null)
        if (config.data != null)
            if (config.data.responseCode === '92' || config.data.responsecode === '92'){
                swal('Session Expired!',config.data.responsemessage != null?config.data.responsemessage:config.data.responseMessage,'info').then(res=>{
                    localStorage.clear();
                    console.log(res)
                    router.push('/').then()
                })
            }
    return config
});

const qooSquareProfile = axios.create({
    baseURL: qs2ProfileBaseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});


qooSquareProfile.interceptors.request.use(config => {
    const token = store.getters.getToken;
    const tok = store.getters["auth/getTok"];
    config.headers.Authorization = (token == null)? tok: token;
    return config
});

qooSquareProfile.interceptors.response.use(config => {
    if (config != null)
        if (config.data != null)
            if (config.data.responseCode === '92' || config.data.responsecode === '92'){
                swal('Session Expired!',config.data.responsemessage != null?config.data.responsemessage:config.data.responseMessage,'info').then(res=>{
                    localStorage.clear();
                    console.log(res)
                    router.push('/').then()
                })
            }
    return config
});


const rubies = axios.create({
    baseURL: rubiesBaseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});


rubies.interceptors.request.use(config => {
    const token = store.getters.getToken;
    const tok = store.getters["auth/getTok"];
    config.headers.Authorization = (token == null)? tok: token;
    return config
});

rubies.interceptors.response.use(config => {
    if (config != null)
        if (config.data != null)
            if (config.data.responseCode === '92' || config.data.responsecode === '92'){
                swal('Session Expired!',config.data.responsemessage != null?config.data.responsemessage:config.data.responseMessage,'info').then(res=>{
                    localStorage.clear();
                    console.log(res)
                    router.push('/').then()
                })
            }
    return config
});


const singleSignOn = axios.create({
    baseURL: singleSignOnBaseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});


singleSignOn.interceptors.request.use(config => {
    const token = store.getters.getToken;
    const tok = store.getters["auth/getTok"];
    config.headers.Authorization = (token == null)? tok: token;
    return config
});

singleSignOn.interceptors.response.use(config => {
    if (config != null)
        if (config.data != null)
            if (config.data.responseCode === '92' || config.data.responsecode === '92'){
                swal('Session Expired!',config.data.responsemessage != null?config.data.responsemessage:config.data.responseMessage,'info').then(res=>{
                    localStorage.clear();
                    console.log(res)
                    router.push('/').then()
                })
            }
    return config
});

const apiClient = {
    qooSquare:qooSquare,
    qooSquareProfile:qooSquareProfile,
    rubies:rubies,
    singleSignOn:singleSignOn
};

export default apiClient;
