<template>
    <b-modal id="actions-modal" :hide-header="true" :hide-footer="true" :centered="true" ref="actions-modal">
        <div class="modal-body">
            <a href="#" role="button" class="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"
               @click="hide()">
                <span aria-hidden="true">&times;</span>
            </a>
            <div>
                <h4>Actions</h4>
                <b-form @submit.prevent="submit" enctype="multipart/form-data">
                    <b-form-group label="Action Type">
                        <b-select required v-model="model.status">
                            <b-select-option  value="">Select action type</b-select-option>
                            <b-select-option value="DELETED">DELETE</b-select-option>
                            <b-select-option value="SUSPENDED">SUSPEND</b-select-option>
                            <b-select-option value="ACTIVE">ACTIVATE</b-select-option>
                            <b-select-option v-if="screen === 'employeeDetail'"  value="RESIGNED">RESIGN</b-select-option>
                            <b-select-option v-if="screen === 'employeeDetail'"  value="DISMISSED">DISMISS</b-select-option>
                        </b-select>
                    </b-form-group>
                    <base-button title="Submit" :loading="isLoading"/>
                </b-form>
            </div>
        </div><!-- modal-body -->
    </b-modal>
</template>
<script>
    import { mapState } from "vuex"
    import BaseButton from "../button/BaseButton";
    export default {
        props:['show','row'],
        components:{BaseButton},
        name: 'ConfirmModal',
        data(){
            return{
                screen:'general',
                password: '',
                model:{
                    status: '',
                    id:''
                },
                readType:'variablePayment/updateVariablePayments',
                editType:'variablePayment/updateStatusVariablePayment'
            }
        },
        methods:{
            hide() {
                this.$refs['actions-modal'].hide()
            },
            async submit(){
                this.model.employeeDetailStatus = this.model.status
                await this.$store.dispatch(this.editType, this.model, {root: false});
                this.loadGrade();
                this.hide();
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.model).forEach(function(key,index) {
                    self.model[key] = '';
                });

            },
            gradeCode(){
                return  localStorage._gradeCode
            },
            loadGrade(){
                this.$store.dispatch(this.readType, {
                    readAll:'NO',
                    employerId: localStorage.orgId,
                    employerGradeSalaryGradeCode: localStorage._gradeCode,
                    employerGradeSalaryEmployerId: localStorage.orgId
                }, {root: false}).then()
            },
            hideauth: function () {
                this.password = '';
                this.$refs['actions-modal'].hide();
            },
            currentDate(){
                let date = new Date()
                date = this.$moment(date.toDateString()).format('YYYY-MM-DD')
                console.log(date)
                return date
            },
        },
        mounted() {
            //this.model = this.row
        },
        computed:{
            ...mapState(['grade','auth','variablePayment','employee']),
            isLoading(){
                if (this.row.employerGradeSalaryId != null)
                    return this.grade.gradeLoading;
                else if (this.row.employeeVariablePayId != null)
                    return this.variablePayment.variablePaymentLoading
                else
                  return this.employee.employeeLoading
            }
        },
        watch: {
            show: function () {
                let self = this;
                // eslint-disable-next-line no-unused-vars
                Object.keys(this.row).forEach(function(key,index) {
                    self.model[key] = self.row[key];
                });
                if (this.row.employeeVariablePayId != null){
                    this.readType = 'variablePayment/updateVariablePayments';
                    this.editType = 'variablePayment/updateStatusVariablePayment';
                    this.model.id = this.row.employeeVariablePayId
                }else if(this.row.employerGradeSalaryId != null){
                    this.readType = 'grade/updateGradeSalaries';
                    this.editType = 'grade/updateGradeSalaryStatus';
                    this.model.id = this.row.employerGradeSalaryId
                }else if(this.row.employeeDetailId != null){
                    this.readType = 'employee/updateEmployees';
                    this.editType = 'employee/updateEmployeeDetailStatus';
                    this.model.id = this.row.employeeDetailId
                    this.model.employeeDetailId = this.row.employeeDetailId
                    this.screen = 'employeeDetail'
                }
                this.$refs['actions-modal'].show()
            }
        },
    }
</script>
